<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-appstatus"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-appstatus">
    <div class="capitalize">{{rowData.status.toLowerCase()}}</div>
    <div class="text-sm" v-if="rowData.status !== 'ACTIVE'">{{formatDate(rowData.updatedAt)}}</div>
  </td>
</template>

<script>
  import VuetableFieldMixin from "vue3-vuetable/src/components/VuetableFieldMixin";
  import moment from 'moment';

  export default {
    name: "AppStatusField",
    mixins: [VuetableFieldMixin],
    methods: {
      formatDate(date) {
        return moment(date).format('LLL')
      }
    }
  }
</script>
